$table-body-row-hover: rgba(0, 82, 106, 0.28);

.table {
  display: flex;
  flex: 1 0 1px;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;

  &-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .table-header-actions {
    width: 100%;
    border-bottom: 1px solid #d5d6d8;

    tr {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      gap: 15px;
      padding: 5px 0;

      th {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }

    &-row {
      display: flex;
      padding: 6px 0;
      justify-content: flex-end;
      align-items: center;
      color: #e0e0e0;
      border-bottom: 1px solid #d5d6d8;
    }
  }

  .table-header {
    flex: 0;
    width: 100%;
    position: sticky;

    .table-header-row {
      height: 24px;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      user-select: none;
      color: #e0e0e0;
      border-bottom: 1px solid #d5d6d8;

      .table-cell {
        &-auto {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;

          span {
            margin-right: 5px;
          }
        }

        &-1 {
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            margin-right: 5px;
          }
        }

        &-2 {
          width: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            margin-right: 5px;
          }
        }

        &-3 {
          width: 121px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .table-body {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
    max-height: 550px;

    .table-body-row {
      height: 50px;
      display: flex;
      width: 100%;
      align-items: stretch;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid $grey;
      cursor: pointer;

      &-selected,
      &:hover {
        background-color: $primary-color-dark;
      }

      &:hover {
        background-color: $table-body-row-hover;
      }

    }
  }

  .table-cell {
    &-image, &-icon {
      display: flex;
      align-items: center;
      width: 36px;
      height: 36px;
      margin-right: 10px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }

    &-auto {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-1 {
      width: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 18px;
    }

    &-2 {
      width: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-3 {
      width: 121px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
